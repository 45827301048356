/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".main-navigation";

#{$className} {
    text-transform: uppercase;
    padding: 0;

    .navbar-nav:has(.show) {
        background-color: $blue;
        color: $white;

        .dropright {
            width: 100%;
            cursor: pointer;

            @include media-breakpoint-down(lg) {
                display: flex;
                flex-direction: column;
            }

            &-btn {
                &:hover {
                    color: #16181b;
                    text-decoration: none;
                    background-color: #f8f9fa;
                }
            }


            &-menu {
                position: absolute;
                top: 0 !important;

                @include media-breakpoint-down(lg) {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    transform: none !important;

                    a {
                        padding-left: 56px;
                    }
                }
            }
        }
    }

    &-link {
        &.active {
            font-weight: 500;
        }

        &::after {
            float: right;
            margin-top: .5em;
        }

        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid $gray-300;
        }

        @include media-breakpoint-up(lg) {
            &::after {
                float: none;
                margin-top: 0;
            }

            &:hover {
                background-color: $blue;
                color: $white;
            }
        }
    }
    //The menu toggle button
    &-toggle {
        padding: .6rem;

        &[aria-expanded="true"] {
            #{$className}-toggle-open {
                display: none;
            }

            #{$className}-toggle-close {
                display: block;
            }
        }

        &[aria-expanded="false"] {
            #{$className}-toggle-open {
                display: block;
            }

            #{$className}-toggle-close {
                display: none;
            }
        }
    }

    &-toggle-icon {
        fill: $white;
        width: 2rem;
        height: 2rem;
    }

    &-search {
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;

        @include media-breakpoint-up(lg) {
            border-top: 0;
            border-bottom: 0;
        }
    }
}

.dropdown-item .dropdown-toggle .sub-menu {
    :hover {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
    }
}
