/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".RelatedItem";

#{$className} {
    &-link {
        &:hover {
            text-decoration: none;
            color: inherit;

            #{$className}-header {
                text-decoration: underline;
            }
        }
    }

    &-desc {
        color: $black; 
    }
}
