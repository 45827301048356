﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/main.scss" />

$className: ".NewsImage";

#{$className} {
    &-image {
        width: inherit;
        height: inherit;
    }
}
