﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/main.scss" />

$className: ".NewsBlogList";

#{$className} {
    img {
        margin-right: 1rem;
    }
}

.filter {
    ul.list-group {
        margin-bottom: 1rem;

        .active {
            a {
                color: #fff;
            }

            .badge-primary, .bg-primary {
                background-color: #fff !important;
                color: #007bff;
            }
        }
    }

    a.badge-pill {
        padding: 1em;
    }
}
