﻿/// <reference path="variables.scss" />

// Luwa custom 
//Calculate the right rem value
@function calculateRem($size) {
    $remSize: calc($size / $font-size-default);
    @return #{$remSize}rem;
}

//Font size to rem
@mixin font-size-to-rem($size) {
    font-size: calculateRem($size);
}
