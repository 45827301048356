/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

.service-menu {
    @include font-size-to-rem(16px);
    padding: 0;

    @include media-breakpoint-up(lg) {
        @include font-size-to-rem(14px);
    }

    &-wrap {
        @include media-breakpoint-up(lg) {
            border-top: 0;
        }
    }

    &-link {
        color: $blue;
        border-bottom: 1px solid $gray-300;
        position: relative;

        &::after {
            float: right;
            margin-top: .5em;
        }

        @include media-breakpoint-up(lg) {
            color: $black;
            border-bottom: 0;

            &::after {
                float: none;
                margin-top: 0;
            }

            &:hover {
                color: $black;
                text-decoration: underline;
            }
        }
    }
     
    &-dropdown {
        @include media-breakpoint-down(lg) {
            border: 0;
            border-bottom: 1px solid $gray-300;
            border-radius: 0;
            background-color: transparent;
        }
    }
}