﻿
.form-page {

    .hidden {
        display: none !important;
    }

    input[type="submit"] {
        color: #fff;
        background-color: #0060a0;
        -webkit-appearance: button;
        text-transform: uppercase;
        border: 1px solid #005b98;
        width: calc(6em + .75rem + 2px);
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

        &:hover {
            color: #fff;
            background-color: #00497a;
            border-color: #00416d;
            border: 1px solid #005b98;
        }
    }
}
