/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".LinkCollection";

#{$className} {
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-list-item {
        width: 100%;
        margin-bottom: $spacer-3;
        @include font-size-to-rem(18px);

        @include media-breakpoint-up(md) {
            width: calc(50% - #{$spacer-3});
            margin-right: $spacer-3;
        }

        @include media-breakpoint-up(lg) {
            width: calc(33% - #{$spacer-3});
        }
    }

    &-link {
        display: block;
        background-color: $cyan;
        color: $white;
        padding: $spacer-2 $spacer-3;
        position: relative;

        &:hover {
            text-decoration: none;
            background-color: $blue;
            color: $white;
        }

        @include media-breakpoint-up(md) {
            &::after {
                content: "";
                position: absolute;
                right: -.5rem;
                top: -1px;
                height: 105%;
                width: 1rem;
                background-color: $white;
                transform: skewX(-$skew-x-deg);
            }
        }
    }
}
