/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".footer-navigation";

#{$className} {

    #{$className}-title {
        @include font-size-to-rem(18px);
    }

    #{$className}-list {
        @include font-size-to-rem(13px);

        a:hover {
            text-decoration: none
        }
    }
}
