﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".CarouselSlider";

#{$className} {
    .SliderImage {
        position: relative;
        max-width: 100%;
        height: auto;
        max-height: 600px;
    }

    .Underlay {
        &:hover {
            background-color: rgba(255, 255, 255, .2);
        }

        .carousel-control-next-icon, .carousel-control-prev-icon {
            width: 50px;
            height: 50px;
        }
    }

    &.editor {
        .wrapper {
            position: relative;

            .inner-wrapper {
                position: absolute;
                top: 10%;

                .richtext-overlay {
                    border-width: 1px;
                    border-style: solid;
                    border-color: #ef8521;
                }
            }
        }
    }
}
