﻿/*
    Colors:
*/

$background-color: #e5e7e8;
$color-gray: #444;
$color-gray2: #333;
$color-gray3: #ccc;
$color-gray4: #6b7a82;
$color-gray5: #eee;
$color-gray6: #e2e2e2;
$color-gray8: #839198; 
$color-blue: #006099;
$color-blue2: #0060a0;
$color-blue3: #009add;
$color-blue3a: #009add;
$color-blue4: #d3ecfc;
$color-orange: #ef8521;
$color-light-blue: #e5f5fc;