﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".products-navigation";

#{$className} {
    &.dropdown-menu {
        padding: 10px;
    }

    &.products-menu {
        margin-left: -175px;
        width: 1200px;
        background-color: $blue;
        border: none;
    }

    #{$className}-showall {
        position: absolute;
        bottom: 15px;
        @include font-size-to-rem(13px);
        text-transform: uppercase;
        right: 20px;
    }

    #{$className}-item {
        background: #fff;
        min-height: 220px;
        color: #006099;
        position: relative;
        border-top: 20px solid;
        padding-top: 10px;
        @include font-size-to-rem(13px);

        &.mobile {
            border-color: $pm-yellow;
        }

        &.stationary {
            border-color: $pm-indigo;
        }

        &.capture {
            border-color: $pm-pink;
        }

        &.fans {
            border-color: $pm-grey;
        }

        &.ducts {
            border-color: $pm-blue;
        }

        &.fluid {
            border-color: $pm-green;
        }

        &.swarf {
            border-color: rgba(113, 126, 133, .6);
        }

        &.auxiliaries {
            border-color: $pm-purple;
        }

        &-inner {
            float: left;
            padding: 5px 20px;
            width: 100%;
            text-transform: none;

            h3 {
                @include font-size-to-rem(18px);
                font-weight: lighter;
                text-transform: none;
            }

            #{$className}-list {
                padding-left: 0;
                padding-top: 10px;
                @include font-size-to-rem(13px);

                &ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    text-transform: none;
                }

                li {
                    list-style-type: none;
                    line-height: 2;
                }
            }
        }
    }
}
