/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

.header {
    background-color: $white;
    position: relative;
    z-index: 2;
    // If startpage we need to position the hero absoloutely
    &.is-startpage {
        background-color: rgba($gray-100, .8);
        z-index: $zindex-fixed;
    }

    &-logo {
        height: 38px;
        width: 160px;

        @include media-breakpoint-up(lg) {
            width: 240px;
            height: 57px;
        }
    }

    &-dropdown {
        @include media-breakpoint-down(lg) {
            position: static !important;
            float: none !important;
            transform: none !important;
        }
    }
}
