﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/main.scss" />

$className: ".cookie-consent";

#{$className} {
    max-width: 85%;
    span {
        border-radius: 1rem;
    }
}
