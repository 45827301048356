﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

.site-links {

    .modal-content {
        background: rgba(0, 0, 0, .9);
        color: #fff;
        padding: 10px 30px;
        border-radius: 0;

        .modal-title {
            margin-top: 5px;
            margin-bottom: 30px;
        }

        .modal-header {
            border-bottom: 0;
            padding: 15px 0 0 15px;
        }

        .close {
            color: #fff;
            opacity: 1;
            font-size: 1.5em;
        }

        a {
            display: block;
            color: #fff;
            font-size: smaller;
            padding-bottom: 20px;

            &.active {
                color: #009add;
            }
        }

        hr {
            border-top: 1px solid #eee;
            margin: 0;
        }
    }
}
