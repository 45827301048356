﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/main.scss" />

$className: ".NewsList";

#{$className} {
    &-image {
        height: inherit;
        width: 100%;
    }
}
