
.u {
    // Font sizes
    &-text-xs {
        font-size: calculateRem(14px);
    }
    //Borders
    &-bt-xl {
        border-top: 1rem solid $cyan;
    }
    //Spacing
    &-spacer {
        padding: 3rem 1rem;

        @media (min-width: 768px) {
            padding: 3rem;
        }
    }

    &-limitPageWidth {
        max-width: 1200px;
        position: relative;
    }

    &-animated-link {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: .25rem 1rem .25rem 3rem;
        text-transform: uppercase;
        transition: all .45s;

        &-arrow {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1rem;
            height: 2rem;
            width: 2rem;
            background-color: $blue;
            border-radius: 4rem;
            display: inline-block;
            z-index: -1;
            transition: all .45s;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: .5rem;
                height: 1px;
                background-color: $white;
                position: absolute;
                left: .75rem;
                top: .9rem;
                transition: all .45s;
            }

            &::before {
                transform: rotate(40deg);
                transform-origin: bottom right;
            }

            &::after {
                transform: rotate(-40deg);
                transform-origin: top right;
            }
        }

        &-shaft {
            position: absolute;
            left: .75rem;
            top: .9rem;
            height: 1px;
            width: .5rem;
            background-color: $white;
            transition: all .45s;
            opacity: 0;
        }
        //When hovering
        &:hover, &.hover {
            color: $white;
            text-decoration: none;

            .u-animated-link-arrow {
                width: 100%;

                &::before,
                &::after {
                    left: 1.34rem;
                }
            }

            .u-animated-link-shaft {
                opacity: 1;
                width: 1rem;
            }
        }
    }
}
.form-group {
    margin-bottom: 1rem;
}