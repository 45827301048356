﻿/*
    Thumbnail Style
*/

@import "_variables";

.thumbnail {
    position: relative;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;

    .thumbnail__img-wrapper {
        overflow: hidden;
        background-size: cover;
        margin-bottom: 15px;
    }

    .thumbnail__inner {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;

        a {
            font-size: 140%;
        }
    }

    .thumbnail__heading {
        color: $color-blue;
        margin-top: 20px;
        margin-bottom: 20px;

        @media (max-width: 991px) and (min-width: 768px) {
            margin-top: 10px;
        }
    }

    .thumbnail__content {
        margin-top: 25px;
    }

    .thumbanil__half-bottpositioned {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    .thumbnail__flag {
        display: inline-block;
        background-color: white;
        height: initial;
        margin-top: 20px;
        margin-left: -10px;
        min-width: 200px;
        padding-right: 20px;
        -moz-transform: skewX(-11.5deg);
        -ms-transform: skewX(-11.5deg);
        -o-transform: skewX(-11.5deg);
        -webkit-transform: skewX(-11.5deg);
        transform: skewX(-11.5deg);
        position: absolute;
        top: 0;

        @media (max-width: 1229px) {
            height: 45px;
        }

        @media (max-width: 991px) {
            height: 37px;
            min-width: 120px;
        }
    }

    .thumbnail__flag-content {
        color: $color-blue;
        padding-left: 25px;
        font-size: 50%;
        -moz-transform: skewX(11.5deg);
        -ms-transform: skewX(11.5deg);
        -o-transform: skewX(11.5deg);
        -webkit-transform: skewX(11.5deg);
        transform: skewX(11.5deg);

        h2 {
            margin-top: .5rem;
            margin-bottom: .5rem;

            @media (max-width: 1229px) {
                font-size: 200%;
            }

            @media (max-width: 991px) {
                font-size: 140%;
            }
        }

        h5 {
            margin-top: .5rem;
            margin-bottom: .5rem;

            @media (max-width: 1229px) {
                font-size: 200%;
            }

            @media (max-width: 991px) {
                font-size: 140%;
            }
        }
    }

    &.transparent {
        background-color: transparent;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: auto;
    }

    @media (min-width: 768px) {
        &.limited {
            margin-bottom: 30px;
            overflow: hidden;

            &.height_small {
                height: 440px;
            }

            &.height_large {
                height: 500px;
            }
        }
    }
}

.large_spot_link {
    color: inherit;
    font-size: inherit !important;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    &.aspNetDisabled {
        &:hover {
            cursor: default;
        }
    }
}

/* horizontal thumbnail */
.thumbnail--horizontal {
    display: flex;
    padding-bottom: 0;

    .thumbnail__half--horizontal {
        width: 50%;

        img {
            padding-left: 8px;
        }
    }

    .thumbnail__half-img {
        background-repeat: no-repeat;
        min-height: 271px;
        background-size: cover;
    }

    .thumbnail__content--horizontal {
        padding: 15px;
    }

    @media (max-width:991px) {
        display: block;

        .thumbnail__half--horizontal {
            width: 100%;

            img {
                padding-left: 0;
            }
        }
    }
}

/* show content on mouse over */
.mouse-overed {
    .thumbnail__inner {
        background-color: #fff;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        position: absolute;
        opacity: 0;
    }

    &:hover {
        .thumbnail__inner {
            width: 100%;
            opacity: 1;
            -moz-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
        }
    }
}