@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=059911ce-a243-4d9f-bb8c-364608f5c7db");

@font-face {
    font-family: "Soho Gothic";
    src: url("/ui/fonts/Luwa/3a99a203-9acc-47bf-acc0-795534d5b104.woff2") format("woff2"), url("/ui/fonts/Luwa/16716e40-a794-41a1-b88a-0f7df4fc7a19.woff") format("woff");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Soho Gothic";
    src: url("/ui/fonts/Luwa/598b0845-9116-49c3-921c-0faad7a564df.woff2") format("woff2"), url("/ui/fonts/Luwa/df20967e-6948-4e78-a4a5-bae27b4a03b9.woff") format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Soho Gothic";
    src: url("/ui/fonts/Luwa/d45caedf-1aa7-4414-910c-2b1b7f261a30.woff2") format("woff2"), url("/ui/fonts/Luwa/dbdf858a-b51c-45fd-8551-ec94798d66f2.woff") format("woff");
    font-weight: 600;
    font-display: swap;
}

@import "bootstrap-overrides/variables";
@import "../../../../../../Foundation/Bootstrap/code/ui/styles/Bootstrap/variables-dark";
@import "../../../../../../Foundation/Bootstrap/code/ui/styles/Bootstrap/maps";
@import "../../../../../../Foundation/Bootstrap/code/ui/styles/Bootstrap/mixins";
@import "bootstrap-overrides/mixins";
@import "../../../../../../Foundation/Bootstrap/code/ui/styles/Bootstrap/utilities";
@import "bootstrap-overrides/utilities";
@import "bootstrap-overrides/vendor/rfs";
//Maps
@import "../../../../../../Feature/Maps/code/ui/styles/Maps/GoogleMap";

//Navigation
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/header";
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/service-menu";
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/main-navigation";
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/_footer-navigation";
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/_sub-navigation";
@import "../../../../../../Feature/Navigation/code/ui/styles/Navigation/_site-links";

//News
@import "../../../../../../Feature/News/code/ui/styles/News/NewsImage";
@import "../../../../../../Feature/News/code/ui/styles/News/NewsList";
@import "../../../../../../Feature/News/code/ui/styles/News/NewsTeaser";
@import "../../../../../../Feature/News/code/ui/styles/News/NewsBlogList";

//PageContent
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/Hero";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/LinkCollection";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/PDFCollection";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/RelatedItem";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/CardItem";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/Spot";
@import "../../../../../../Feature/PageContent/code/ui/styles/PageContent/thumbnail";

//Common
@import "../../../../../../Project/Common/code/ui/styles/Common/Footer";
@import "../../../../../../Project/Common/code/ui/styles/Common/EditorWrapper";

//Slider
@import "../../../../../../Feature/Slider/code/ui/styles/Slider/Slider";

//Identity
@import "../../../../../../Feature/Identity/code/ui/styles/Identity/ContactUsFloating";

//Products
@import "../../../../../../Feature/Products/code/ui/styles/Products/_products-loading.scss";
@import "../../../../../../Feature/Products/code/ui/styles/Products/_products-navigation";

//Forms
@import "../../../../../../Feature/Forms/code/ui/styles/Forms/_nederman-forms-modal";
@import "../../../../../../Feature/Forms/code/ui/styles/Forms/_nederman-forms-page";

//Cookies
@import "../../../../../../Feature/Cookies/code/ui/styles/Cookies/_CookieConsent";


label {
    margin-bottom: 0.5rem;
}