/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".CardItem";

#{$className} {
    overflow: hidden;

    img { /* Needed for experience editor */
        max-width: 100%;
        height: auto;
    }

    &-link {
        color: inherit;

        * {
            color: inherit;
        }

        &:hover {
            text-decoration: none;

            #{$className}-text {
                color: inherit;
                text-decoration: none;
            }

            #{$className}-title {
                text-decoration: underline;
            }
        }
    }
}
