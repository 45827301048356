﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/main.scss" />

$className: ".NewsTeaser";

#{$className} {
    &-image {
        width: 135px;
        height: 135px;
        object-fit: cover;
    }
}
