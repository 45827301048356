﻿/*
    Thumbnail Style
*/
/*
    Colors:
*/
.thumbnail {
    position: relative;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

.thumbnail .thumbnail__img-wrapper {
    overflow: hidden;
    background-size: cover;
    margin-bottom: 15px;
}

.thumbnail .thumbnail__inner {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
}

.thumbnail .thumbnail__inner a {
    font-size: 140%;
}

.thumbnail .thumbnail__heading {
    color: #006099;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 991px) and (min-width: 768px) {
    .thumbnail .thumbnail__heading {
        margin-top: 10px;
    }
}

.thumbnail .thumbnail__content {
    margin-top: 25px;
}

.thumbnail .thumbanil__half-bottpositioned {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.thumbnail .thumbnail__flag {
    display: inline-block;
    background-color: white;
    height: initial;
    margin-top: 25px;
    margin-left: -10px;
    min-width: 200px;
    padding-right: 20px;
    -moz-transform: skewX(-11.5deg);
    -ms-transform: skewX(-11.5deg);
    -o-transform: skewX(-11.5deg);
    -webkit-transform: skewX(-11.5deg);
    transform: skewX(-11.5deg);
    position: absolute;
    top: 0;
}

@media (max-width: 1229px) {
    .thumbnail .thumbnail__flag {
        height: 45px;
    }
}

@media (max-width: 991px) {
    .thumbnail .thumbnail__flag {
        height: 37px;
        min-width: 120px;
    }
}

.thumbnail .thumbnail__flag-content {
    color: #006099;
    padding-left: 25px;
    font-size: 75%;
    -moz-transform: skewX(11.5deg);
    -ms-transform: skewX(11.5deg);
    -o-transform: skewX(11.5deg);
    -webkit-transform: skewX(11.5deg);
    transform: skewX(11.5deg);
}

.thumbnail .thumbnail__flag-content h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1229px) {
    .thumbnail .thumbnail__flag-content h2 {
        font-size: 200%;
    }
}

@media (max-width: 991px) {
    .thumbnail .thumbnail__flag-content h2 {
        font-size: 140%;
    }
}

.thumbnail.transparent {
    background-color: transparent;
    overflow: hidden;
}

.thumbnail img {
    width: 100%;
    height: auto;
    display: block;
}

@media (min-width: 768px) {
    .thumbnail.limited {
        margin-bottom: 30px;
        overflow: hidden;
    }

    .thumbnail.limited.height_small {
        height: 440px;
    }

    .thumbnail.limited.height_large {
        height: 500px;
    }
}

.large_spot_link {
    color: inherit;
    font-size: inherit !important;
}

.large_spot_link:hover, .large_spot_link:focus {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.large_spot_link.aspNetDisabled:hover {
    cursor: default;
}

/* horizontal thumbnail */
.thumbnail--horizontal {
    display: flex;
    padding-bottom: 0;
}

.thumbnail--horizontal .thumbnail__half--horizontal {
    width: 50%;
}

.thumbnail--horizontal .thumbnail__half--horizontal img {
    padding-left: 8px;
}

.thumbnail--horizontal .thumbnail__half-img {
    background-repeat: no-repeat;
    min-height: 271px;
    background-size: cover;
}

.thumbnail--horizontal .thumbnail__content--horizontal {
    padding: 15px;
}

@media (max-width: 991px) {
    .thumbnail--horizontal {
        display: block;
    }

    .thumbnail--horizontal .thumbnail__half--horizontal {
        width: 100%;
    }

    .thumbnail--horizontal .thumbnail__half--horizontal img {
        padding-left: 0;
    }
}

/* show content on mouse over */
.mouse-overed .thumbnail__inner {
    background-color: #fff;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: absolute;
    opacity: 0;
}

.mouse-overed:hover .thumbnail__inner {
    width: 100%;
    opacity: 1;
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
