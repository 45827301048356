﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".ContactUsFloating";

#{$className} {
    position: fixed;
    right: 0;
    bottom: 100px;
    z-index: 1000;

    a div {
        background-color: #ccc;
        height: 60px;
        border-radius: 3px 0 0 3px;

        span {
            margin: 0 15px;
            text-transform: uppercase;
            vertical-align: middle;
        }
    }
}