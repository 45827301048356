/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".Hero";

#{$className} {
    position: relative;
    z-index: 1;
    min-height: 200px;
    overflow: hidden;
    display: flex;

    @include media-breakpoint-up(md) {
        min-height: 300px;
        height: 20vw;
    }

    &.is-startpage {
        @include media-breakpoint-up(lg) {
            margin-top: -$header-height;
            min-height: 400px;
        }
    }

    &-video {
        &.is-startpage {
            @include media-breakpoint-up(lg) {
                margin-top: 0px;
                min-height: 450px;
            }
        }
    }

    &::before {
        content: none;
    }

    #{$className}-container {
        position: absolute;
        bottom: $spacer-2;
        left: 0;
        right: 0;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            bottom: $spacer-5;
        }

        .col-md-8 {
            position: relative
        }

        &-video {
            position: absolute !important;

            @include media-breakpoint-up(lg) {
                bottom: 3rem;
            }

            @include media-breakpoint-down(xl) {
                bottom: 1.5rem;
            }
        }
    }

    &-title-wrap {
        opacity: .9;
        background-color: $cyan;

        @include media-breakpoint-up(md) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                display: block;
                width: 40px;
                background-color: $cyan;
                transform: skewX(-$skew-x-deg);
            }
        }
    }

    &-container {
        position: relative;
        z-index: 10;
    }

    &-img {
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        width: 100%;
        transform: translate(-50%,-50%) scale(1,1);
        min-width: 100%;
        min-height: 100%;
    }

    &-header {
        color: $white;
        position: relative;
        padding: $spacer-3;
        margin: 0;
        font-weight: 400;
        font-size: calculateRem(22px);

        @include media-breakpoint-up(sm) {
            font-size: calculateRem(28px);
        }

        @include media-breakpoint-up(md) {
            font-size: calculateRem(36px);
        }

        @include media-breakpoint-up(lg) {
            font-size: calculateRem(48px);
        }

        &.video {
            padding: 0;
        }
    }

    &-title-wrap {
        display: block;

        &:hover {
            text-decoration: none;
        }
    }
}
