﻿/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".GoogleMap";

#{$className} {

    #map {
        height: 600px;
        width: 100%;
    }

    #legend {
        font-family: Arial, sans-serif;
        background: #fff;
        padding: 10px;
        margin: 10px;
        border: 1px solid #000;
    }

    .legend-row {
        margin: 5px;

        & img {
            height: 32px;
            width: 32px;
        }
    }

    #legend img {
        vertical-align: middle;
    }

    .contact-us-infowindow {
        width: auto;
        height: auto;

        .title {
            font-size: 2em;
        }
    }
}
