/// <reference path="../../../../../../Project/Luwa/code/ui/styles/Luwa/imports.scss" />

$className: ".Footer";

#{$className} {
    &-svg-icon {
        width: 1.5rem;
        height: 1.5rem;
        fill: $white;
    }

    &-title {
        border-bottom: 1px solid rgba($white,.2);
        padding-bottom: $spacer-3;
    }

    &-list {
        line-height: 2rem;
        font-size: calculateRem(14px);
    }

    &-copy {
        border-top: 1px solid rgba($white,.2);
    }

    &-link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
